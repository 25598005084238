.buttonnnn {
  --main-focus: #2d8cf0;
  /* --font-color: #dedede; */
  --font-color: #fff;
  --bg-color-sub: #dedede;
  --bg-color: #0f686b;
  --main-color: #323232;
  position: relative;
  /* width: 150px; */
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid var(--main-color);
  box-shadow: 3px 3px var(--main-color);
  background-color: var(--bg-color);
  border-radius: 526pxpx;
  overflow: hidden;
  margin: 40px 0;
}

@media only screen and (max-width: 1024px) {
  .buttonnnn {
    margin: 5px 0 34px 0;
  }
}
.buttonnnn,
.buttonnnn__text,
.buttonnnn__icon {
  transition: all 0.1s;
}

.buttonnnn .buttonnnn__text {
  /* transform: translateX(30px); */
  color: var(--font-color);
  font-size: 18px;
  font-weight: 700;
  font-family: "JetBrains Mono", monospace;
}

.buttonnnn:hover {
  background: var(--bg-color);
}

.buttonnnn:hover .buttonnnn__text {
  color: #f06000;
}
.buttonnnn:hover .buttonnnn__icon {
  fill: #f06000;
}

.buttonnnn:active {
  transform: translate(3px, 3px);
  box-shadow: 0px 0px var(--main-color);
}

@media only screen and (max-width: 300px) {
  .buttonnnn .buttonnnn__text {
    font-size: 16px;
  }
}
