.cards-wrapper {
  max-width: 1415px;
  width: 96%;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: 900px) {
  .cards-wrapper {
    flex-direction: column;
  }

  .card {
    width: 100%;
    margin: 15px auto;
    flex-direction: row;
  }
}

.card {
  width: 29%;
  background: rgb(11, 117, 127);
  color: #073b4c;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  align-items: left;
  position: relative;
  border-radius: 4px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px -1px rgba(0, 0, 0, 0.2);
}

.card:hover {
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px -1px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 900px) {
  .card {
    width: 70%;
  }
}

@media only screen and (max-width: 540px) {
  .card {
    width: 82%;
  }
}



.card__image {
  width: initial;
  margin-bottom: 16px;
  border-radius: 4px;
}

.title {
  font-size: 1.4em;
  line-height: 4rem;
  margin-bottom: 1rem;
}

.card__content {
  display: grid;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  padding: 0px 15px 15px 15px;
}

.card:hover {
  /* box-shadow: -9px 9px 0px 0px #00425b; */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px -1px rgba(0, 0, 0, 0.2), -9px 9px 0px 0px rgb(218, 230, 230);
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 2px 2px -1px rgba(0, 0, 0, 0.2); */
}

/*----------------------------------------arrow-icon----------------------------------------*/
.card__arrow {
  width: 50px;
  height: 50px;
  transform: rotate(0);
  transition: all 0.3s;
  justify-self: end;
}

.card__arrow svg {
  width: 100%;
  height: 100%;
}

.card__arrow g {
  fill: white;
}

.card__arrow circle,
polyline,
line {
  stroke: white;
}

.card:hover .card__arrow {
  transform: rotate(-45deg);
}

.card:hover {
  transform: translate(7px, -7px);
}